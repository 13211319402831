import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "data-i-entered-is-not-there"
    }}>{`Data I entered is not there`}</h1>
    <p>{`If your data is not showing up on another device it most likely is due to a syncing issue.`}</p>
    <p>{`On the device that has provided the data please check`}</p>
    <ul>
      <li parentName="ul">{`whether the entry made is currently being seen in the application`}</li>
      <li parentName="ul">{`the device has an active internet connection`}</li>
    </ul>
    <p>{`Depending on the speed of the Internet connection the synchronisation may take a few minutes to complete. Please allow for 10 minutes to complete the synchronisation.`}</p>
    <h2 {...{
      "id": "my-data-is-still-not-syncing"
    }}>{`My data is still not syncing`}</h2>
    <p>{`Please provide a description of the data that is missing to `}<a parentName="p" {...{
        "href": "mailto:vpm-support@ohb-ds.de"
      }}>{`vpm-support@ohb-ds.de`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      